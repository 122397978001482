import React from 'react';
import {Link} from 'react-router-dom';

export const PostsForYear = ({year, posts}) => {
  return posts && posts.length > 0 ? (
    <div className="mb-6">
      <div className="mt-2 block text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl mb-3">
        <span>{year}</span>
      </div>

      {posts.map(post => (
        <div key={post.slug} className="post">
          <Link to={`/${post.slug}`}>{post.title}</Link>
          <span className="ml-1 text-sm text-gray-500">{post.date}</span>
        </div>
      ))}
    </div>
  ) : null;
}