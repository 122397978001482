import React from "react";
import {Helmet} from 'react-helmet';
import {config} from '../../config';
import {MailToLink} from './components/MailToLink';

export default () => (
  <div className="relative py-16 bg-white overflow-hidden">
    <Helmet>
      <title>Privacy Policy</title>
      <link rel="canonical" href="https://www.pols.co.uk/privacy-policy/"/>
      <meta name="description" content="We only collect and use personal data in ways that are described here."/>
    </Helmet>

    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="text-lg max-w-prose mx-auto">
        <h1>
          <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl" data-testid="heading">Privacy Policy</span>
        </h1>
      </div>
      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>Background: </h2>
        <p>
          {config.company.name} understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of everyone who visits this website,
          {config.website} ("Our Site") and will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the law.
        </p>
        <p>
          Please read this Privacy Policy carefully and ensure that you understand it.
        </p>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>1. Information About Us</h2>
        <p>
          Our Site, {config.website}, is owned and operated by {config.company.name}, a limited company registered in England under {config.company.registrationNumber}, whose registered address
          is {config.company.registeredAddress}.
        </p>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>2. What Does This Policy Cover?</h2>
        <p>
          This Privacy Policy applies only to your use of Our Site. Our Site may contain links to other websites. Please note that we have no control over how your data is collected, stored, or used by other websites and
          we advise you to check the privacy policies of any such websites before providing any data to them.
        </p>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>3. What Is Personal Data?</h2>
        <p>
          Personal data is defined by the UK GDPR and the Data Protection Act 2018 (collectively, “the Data Protection Legislation”) as ‘any information relating to an identifiable person who can be directly or
          indirectly identified in particular by reference to an identifier’.
        </p>
        <p>
          Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers obvious information such as your name and contact details, but it also covers less obvious
          information such as identification numbers, electronic location data, and other online identifiers.
        </p>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>4. What Are My Rights?</h2>
        <p>
          Under the Data Protection Legislation, you have the following rights, which we will always work to uphold:
        </p>
        <ol>
          <li>The right to be informed about our collection and use of your personal data. This Privacy Policy should tell you everything you need to know, but you can always contact us to find out more or to ask any
            questions using the details in Part 12.
          </li>
          <li>The right to access the personal data we hold about you. Part 11 will tell you how to do this.</li>
          <li>The right to have your personal data rectified if any of your personal data held by us is inaccurate or incomplete. Please contact us using the details in Part 12 to find out more.</li>
          <li>The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any of your personal data that we hold. Please contact us using the details in Part 12 to find out more.</li>
          <li>The right to restrict (i.e. prevent) the processing of your personal data.</li>
          <li>The right to object to us using your personal data for a particular purpose or purposes.</li>
          <li>The right to withdraw consent. This means that, if we are relying on your consent as the legal basis for using your personal data, you are free to withdraw that consent at any time.</li>
          <li>The right to data portability. This means that, if you have provided personal data to us directly, we are using it with your consent or for the performance of a contract, and that data is processed using
            automated means, you can ask us for a copy of that personal data to re-use with another service or business in many cases.
          </li>
          <li>Rights relating to automated decision-making and profiling. We do not use your personal data in this way.</li>
          <li>For more information about our use of your personal data or exercising your rights as outlined above, please contact us using the details provided in Part 12.</li>
          <li>It is important that your personal data is kept accurate and up-to-date. If any of the personal data we hold about you changes, please keep us informed as long as we have that data.</li>
          <li>Further information about your rights can also be obtained from the Information Commissioner’s Office or your local Citizens Advice Bureau.</li>
          <li>If you have any cause for complaint about our use of your personal data, you have the right to lodge a complaint with the Information Commissioner’s Office. We would welcome the opportunity to resolve
            your concerns ourselves, however, so please contact us first, using the details in Part 12.
          </li>
        </ol>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>5. What Data Do You Collect and How?</h2>
        <p>
          Depending upon your use of Our Site, we may collect and hold some or all of the personal and non-personal data set out in the table below, using the methods also set out in the table. We do not collect any
          ‘special category’ or ‘sensitive’ personal data or personal data relating to children.
        </p>

        <div className="flex flex-col">
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="min-w-full sm:px-6 lg:px-8">
              <div className="shadow border-b border-gray-200 sm:rounded-lg">
                <table className="divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="ml-4">
                        Data Collected
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="ml-4">
                        How We Collect the Data
                      </div>
                    </th>
                  </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="ml-4">
                          Identity information.
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="ml-4">
                        We do not collect any personal data.
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="ml-4">
                          Contact information.
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="ml-4">
                        We do not collect any contact data.
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="ml-4">
                          Technical information.
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="ml-4">We do not collect any technical data.</div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="ml-4">
                          Data from third parties.
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="ml-4">We do not collect any data from third parties.</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>6. How Do You Use My Personal Data?</h2>
        <p>
          Under the Data Protection Legislation, we must always have a lawful basis for using personal data. The following table describes how we will use your personal data, and our lawful bases for doing so:
        </p>

        <div className="flex flex-col">
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="min-w-full sm:px-6 lg:px-8">
              <div className="shadow border-b border-gray-200 sm:rounded-lg">
                <table className="divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="ml-4">
                        What We do
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="ml-4">
                        What Data We Use
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="ml-4">
                        Our Lawful Basis
                      </div>
                    </th>
                  </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="ml-4">
                        We do not collect any personal data
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="ml-4"></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="ml-4"></div>
                    </td>
                  </tr>
                  </tbody>
                </table>


              </div>
            </div>
          </div>
        </div>

        <p>
          With your permission we may also use your personal data for marketing purposes, which may include contacting you by email with information, news, and offers on our products.
          You will not be sent any unlawful marketing or spam.
          We will always work to fully protect your rights and comply with our obligations under the Data Protection Legislation and the Privacy and Electronic Communications (EC Directive) Regulations 2003, and you will
          always have the opportunity to opt-out.
        </p>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>7. How Long Will You Keep My Personal Data?</h2>
        <p>
          We will not keep your personal data for any longer than is necessary in light of the reason(s) for which it was first collected. Your personal data will therefore be kept for the following periods
          (or, where there is no fixed period, the following factors will be used to determine how long it is kept):
        </p>

        <div className="flex flex-col">
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="min-w-full sm:px-6 lg:px-8">
              <div className="shadow border-b border-gray-200 sm:rounded-lg">
                <table className="divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="ml-4">
                        Type of Data
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="ml-4">
                        How Long We Keep It
                      </div>
                    </th>
                  </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="ml-4">
                        We do not collect any personal data
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="ml-4"></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="ml-4"></div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>8. How and Where Do You Store or Transfer My Personal Data?</h2>
        <p>
          We will only store your personal data in the UK. This means that it will be fully protected under the Data Protection Legislation.
        </p>
      </div>

    </div>

    <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
      <h2>9. Do You Share My Personal Data?</h2>
      <p>
        We will not share any of your personal data with any third parties for any purposes, subject to the following exception.
      </p>
      <p>
        In some limited circumstances, we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or
        the instructions of a government authority.
      </p>
    </div>

    <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
      <h2>10. Can I Withhold Information?</h2>
      <p>
        You may access Our Site without providing any personal data at all.
      </p>
    </div>

    <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
      <h2>11. How Can I Access My Personal Data?</h2>
      <p>
        If you want to know what personal data we have about you, you can ask us for details of that personal data and for a copy of it (where any such personal data is held). This is known as a “subject access request”.
      </p>
      <p>
        All subject access requests should be made in writing and sent to the email or postal addresses shown in Part 12.
      </p>
      <p>
        There is not normally any charge for a subject access request. If your request is ‘manifestly unfounded or excessive’ (for example, if you make repetitive requests) a fee may be charged to cover our
        administrative costs in responding.
      </p>
      <p>
        We will respond to your subject access request within one month. Normally, we aim to provide a complete response, including a copy of your personal data within that time. In some cases, however, particularly if
        your request is more complex,
        more time may be required up to a maximum of three months from the date we receive your request. You will be kept fully informed of our progress.
      </p>
    </div>

    <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
      <h2>12. How Do I Contact You?</h2>
      <p>
        To contact us about anything to do with your personal data and data protection, including to make a subject access request, please use the following details:

      </p>
      <ol>
        <li><span className="font-bold">Email:</span>: <MailToLink url={`mailto:privacy@incrediblesoftware.io`} label="privacy@incrediblesoftware.io"/>.</li>
        <li><span className="font-bold">Postal Address:</span>: {config.company.registeredAddress}.</li>
      </ol>
    </div>

    <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
      <h2>13. Changes to this Privacy Policy</h2>
      <p>
        We may change this Privacy Notice from time to time. This may be necessary, for example, if the law changes, or if we change our business in a way that affects personal data protection.
      </p>
      <p>
        Any changes will be immediately posted on Our Site and you will be deemed to have accepted the terms of the Privacy Policy on your first use of Our Site following the alterations.
        We recommend that you check this page regularly to keep up-to-date.
      </p>
      <p>
        This Privacy Policy was last updated on 10th March 2021.
      </p>
    </div>
  </div>
);