import React, {useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Helmet} from 'react-helmet';
import Prismic from '@prismicio/client';
import {config} from '../../../config'
import {PostSummary} from './PostSummary';
import {Loader} from './Loader';
import {ThatsAllFolks} from './ThatsAllFolks';

const client = Prismic.client(config.prismicApi);

export default () => {
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [posts, setPosts] = useState([]);

  function fetchData() {
    if(!loading) {
      setLoading(true);
      client.query(Prismic.Predicates.at('document.type', 'blog-post'), {pageSize: 25, page: nextPage, orderings: '[my.blog-post.release_date desc]'})
        .then(content => {
          setHasMore(content.page < content.total_pages);
          setNextPage(content.page + 1);
          setPosts([...posts, ...content.results]);
          setLoading(false);
        })
        .catch(err => console.log(err));
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="relative py-16 bg-white overflow-hidden" data-testid="home-page">
        <Helmet>
          <title>Andy Pols' Blog</title>
          <link rel="canonical" href="https://www.pols.co.uk"/>
          <meta name="description" content="I'm a delivery-focused, entrepreneurial, startup CTO. I get a kick out of creating software that people love to use. Welcome to my blog."/>
        </Helmet>

        <div className="relative px-4 sm:px-6 lg:px-8">
          <InfiniteScroll
            dataLength={posts.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<Loader/>}
            endMessage={<ThatsAllFolks/>}
          >
            {posts.map((post, i) => <PostSummary key={i} post={post}/>)}
          </InfiniteScroll>
        </div>
      </div>
    </React.Fragment>
  );
}