import React from 'react';
import {DateTime} from 'luxon';
import {Link} from 'react-router-dom';
import {kebabCase} from 'lodash';

import {Button} from '../components/Button';
import {RichText} from "prismic-reactjs";

export const PostSummary = ({post}) => {
  const title = RichText.asText(post.data.title);
  const date = DateTime.fromISO(post.data.release_date);
  const hasContent = post.data.body.length > 0;

  return (
    <div className="text-lg max-w-prose mb-2 pb-8 mx-auto border-b border-gray-200" data-testid={kebabCase(title)}>
      <p className="text-sm text-gray-500">
        <time dateTime={post.data.release_date}>{date.toLocaleString(DateTime.DATE_MED)}</time>
      </p>
      <Link to={`/${post.uid}`} className="mt-2 block">
        <span className="mt-2 block text-3xl leading-8 font-bold sm:text-4xl text-gray-700">
          {title}
        </span>
      </Link>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <RichText render={post.data.summary}/>
      </div>

      {hasContent && <div className="mt-3">
        <Button url={post.uid} label="Continue reading →" testid={kebabCase(`${title}-more-link`)}/>
      </div>}

      {!hasContent && <div data-testid={kebabCase(`${title}-complete`)}/>}
    </div>
  );
}