/* istanbul ignore file */
import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Home from './Home';
import Post from './Post';
import About from './About';
import Archive from './Archive';
import PapersAndPresentations from './PapersAndPresentations';
import ScrollToTop from './components/ScrollToTop';
import {Footer} from './Footer';
import {Header} from './Header';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import Unsubscribe from './Unsubscribe';

export default () => (
  <Router>
    <Header/>
    <div className="min-h-screen flex flex-col font-sans">
      <div className="flex-grow text-2xl">
        <ScrollToTop>
          <Switch>
            <Route exact path='/about-andy-pols/'>
              <About/>
            </Route>
            <Route exact path='/archive/'>
              <Archive/>
            </Route>

            <Route exact path='/papers-and-presentations/'>
              <PapersAndPresentations/>
            </Route>

            <Route exact path='/terms/'>
              <TermsAndConditions/>
            </Route>

            <Route exact path='/privacy-policy/'>
              <PrivacyPolicy/>
            </Route>

            <Route exact path='/unsubscribe/'>
              <Unsubscribe/>
            </Route>

            <Route exact path='/'>
              <Home/>
            </Route>

            <Route path='/:slug'>
              <Post/>
            </Route>
          </Switch>
        </ScrollToTop>
      </div>
      <div className="bg-indigo text-white text-2xl">
        <Footer/>
      </div>
    </div>
  </Router>
);