import React from 'react';
import {RichText} from 'prismic-reactjs';

const Caption = ({caption}) => {
  return RichText.asText(caption) !== '' ? (
    <p>
        <span className='image-label'>
          {RichText.asText(caption)}
        </span>
    </p>
  ) : null;
};

export const DefaultImage = ({slice}) => (
  <div className="post-part single container">
    <div className={`block-img ${slice.slice_label}`}>
      <img src={slice.primary.image.url} alt={slice.primary.image.alt}/>
      <Caption caption={slice.primary.caption}/>
    </div>
  </div>
);