import React from 'react';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router';
import {TwitterIcon} from './components/icons/TwitterIcon';
import {Button} from './components/Button';
import {GitHubIcon} from './components/icons/GitHubIcon';
import {EmailIcon} from './components/icons/EmailIcon';
import {LinkedInIcon} from './components/icons/LinkedinIcon';
import {config} from '../../config';

export function Header() {
  const location = useLocation();

  return (
    <div className="w-full m-0 p-0 bg-cover bg-bottom">
      <div className="container max-w-4xl mx-auto text-center break-normal">
        <p className="font-extrabold text-3xl md:text-5xl">
          <Link to="/" data-testid="home-link">{config.name}</Link>
        </p>

        <p className="text-xl md:text-2xl text-gray-500">
          Geek, CTO, Entrepreneur
          {location.pathname !== '/about-andy-pols/' && (<span className="pl-2 text-gray-400 underline">(<Link to="/about-andy-pols/" data-testid="about-me-link">more about me</Link>)</span>)}
        </p>

        <div className="mt-4">
          <Button label="@andy_pols" url={config.social.twitter} icon={<TwitterIcon/>} testid="twitter-header-link"/>
          <Button label="andypols" url={config.gitHub} icon={<GitHubIcon/>} testid="github-header-link"/>
          <Button label="andypols" url={config.social.linkedin} icon={<LinkedInIcon/>} testid="linkedin-header-link"/>
          <Button label="Say Hi" url={`mailto:${config.email}?subject=Blog`} icon={<EmailIcon/>}/>
        </div>
      </div>
    </div>
  )
}