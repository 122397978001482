import React from 'react';

export const EmbeddedVideoTubeVideo = ({slice}) => {
  return (
    <iframe width="700"
            height="400"
            frameBorder="0"
            src={`https://www.youtube.com/embed/${slice.primary.youtube[0].text}`} title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
  );
}