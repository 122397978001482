import React from 'react';
import {Link} from 'react-router-dom';
import {MailToLink} from './MailToLink';

const buttonClasses = 'inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-500 bg-white hover:bg-gray-50 focus:outline-none mr-3';


export const Button = ({url, label, icon, testid}) => {
  const external = url.startsWith('http');
  const isMailto = url.startsWith('mailto:');

  const labelProps = icon ? {className: 'ml-1'} : {};

  if(external) {
    return (
      <a href={url} className={buttonClasses} rel="noreferrer" target="_blank" data-testid={testid}>
        {icon}
        <span {...labelProps}>{label}</span>
      </a>
    )
  }

  if(isMailto) {
    return (
      <MailToLink icon={icon} url={url} label={label} labelClasses={icon ? labelProps.className: ''} linkClasses={buttonClasses}/>
    )
  }

  return (
    <Link to={`/${url}`} className={buttonClasses} data-testid={testid}>
      {label}
    </Link>
  );
}