import React from 'react';
import {Helmet} from 'react-helmet';
import {config} from '../../config';
import {PaperLink} from './components/PaperLink';

export default () => (
  <div className="relative py-16 bg-white overflow-hidden">
    <Helmet>
      <title>Andy Pols' Papers & Presentations</title>
      <link rel="canonical" href="https://www.pols.co.uk/papers-and-presentations"/>
      <meta name="description" content="Simple list of all my papers and presentations."/>
    </Helmet>

    <div className="relative px-4 sm:px-6 lg:px-8" data-testid="papers">
      <div className="text-lg max-w-prose mx-auto">
        <h1>
          <span className="mt-2 block text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">Papers & Presentations</span>
        </h1>
      </div>
      <div className="mt-6 mx-auto prose">
        <ul>
          {config.papers.map((paper, i) => <PaperLink key={i} paper={paper}/>)}
        </ul>
      </div>
    </div>
  </div>
);