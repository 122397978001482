export function cleanSlug(location) {
  let slug = location.pathname;
  if(slug.startsWith('/')) {
    slug = slug.substr(1);
  }

  if(slug.endsWith('/')) {
    slug = slug.substr(0, slug.length - 1);
  }

  return slug;
}