import React from 'react';
import ReactMarkdown from 'react-markdown';

export function PaperLink({paper}) {
  return (
    <li>
      <a href={paper.url}>{paper.title}</a> ({paper.fileType}).
      <ReactMarkdown>{paper.summary}</ReactMarkdown>
      <span className="text-gray-400">{paper.date}</span>
    </li>
  );
}