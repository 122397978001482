import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

export default ({children}) => {
  const [pathname, setPathname] = useState(null)
  const location = useLocation();

  useEffect(() => {
    if(location.pathname !== pathname) {
      setPathname(location.pathname);
      window.scrollTo(0, 0);
    }
  }, [location, pathname])

  return children;
}