import React from 'react';

export const MailToLink = ({url, label, icon, linkClasses, labelClasses}) => {
  const handleClick = (event) => {
    event.preventDefault();
    window.location.assign(url);
  }

  const labelProps = icon ? {className: labelClasses} : {};

  return (
    <a href="mailto:obfuscated" className={linkClasses} rel="noreferrer" target="_blank" onClick={handleClick} data-testid="mailto-link">
      {icon}
      <span {...labelProps}>{label}</span>
    </a>
  )
}