import React from 'react';
import {Link} from "react-router-dom";

export default () => {
  return (
    <div className="relative py-16 bg-white overflow-hidden" data-testid="404">
      <article className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1 className="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl" data-testid="heading">
            Oh No, we can't find the page you wanted
          </h1>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          Maybe it's been moved? Got deleted? Never existing in the first place?
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          Let's go <Link to="/">home</Link> or check out the <Link to="/archive">archive</Link>.
        </div>
      </article>
    </div>
  )
}
