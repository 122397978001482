import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Prismic from '@prismicio/client';
import {Helmet} from 'react-helmet';
import {DateTime} from 'luxon';
import {PostsForYear} from './PostsForYear';
import {config} from '../../../config';
import {RichText} from "prismic-reactjs";

const client = Prismic.client(config.prismicApi);

export default () => {
  const [posts, setPosts] = useState([]);

  function fetchPosts() {
    client.query(Prismic.Predicates.at('document.type', 'blog-post'), {pageSize: 125, page: 1, orderings: '[my.blog-post.release_date desc]'})
      .then(content => {
        setPosts(groupByYear(content.results));
      })
      .catch(err => console.log(err));
  }

  const groupByYear = blogPosts => {
    const groupedPosts = {};

    blogPosts.forEach(post => {
      const date = DateTime.fromISO(post.data.release_date);

      const year = date.year;
      groupedPosts[year] = groupedPosts[year] || [];
      groupedPosts[year].push({title: RichText.asText(post.data.title), date: date.toLocaleString(DateTime.DATE_MED), slug: post.uid})
    });

    return groupedPosts;
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return posts ? (
    <div className="relative py-16 bg-white overflow-hidden" data-testid="posts">
      <Helmet>
        <title>Andy Pols' Blog Archive</title>
        <link rel="canonical" href="https://www.pols.co.uk/archive/"/>
        <meta name="description" content="Simple list of all my posts and papers."/>
      </Helmet>

      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl" data-testid="heading">All Blog Posts</span>
          </h1>
        </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            {Object.keys(posts).sort((a, b) => b - a).map(year => <PostsForYear key={year} year={year} posts={posts[year]}/>)}
          </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-900 mx-auto">
          These are various <Link to="/papers-and-presentations/">papers and presentations</Link>.
        </div>
      </div>
    </div>
  ) : null;
}