import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {RichText} from 'prismic-reactjs';
import {Helmet} from 'react-helmet';
import Prismic from '@prismicio/client';
import {config} from '../../../config'
import {SliceZone} from '../components/sliceZone';
import FourOhFour from '../404';
import {cleanSlug} from './cleanSlug';

const client = Prismic.client(config.prismicApi);

export default () => {
  const [post, setPost] = useState();
  const location = useLocation();

  async function fetchPost(slug) {
    client.getByUID('blog-post', slug)
      .then(content => setPost(content || '404'))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    fetchPost(cleanSlug(location));
  }, [location]);

  if(post === '404') {
    return <FourOhFour />;
  }

  return post ? (
    <div className="relative py-16 bg-white overflow-hidden" data-testid={post.slug || '404'}>
      <Helmet>
        <title>Andy Pols: {RichText.asText(post.data.title)}</title>
        <link rel="canonical" href={`https://www.pols.co.uk/${post.slug}`}/>
      </Helmet>

      <article className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1 className="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl" data-testid="heading">
            {RichText.asText(post.data.title)}
          </h1>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <RichText render={post.data.summary}/>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <SliceZone sliceZone={post.data.body}/>
        </div>
      </article>
    </div>
  ) : null;
}