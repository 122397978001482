import React from "react";
import {Helmet} from 'react-helmet';

export default () => (
  <div className="relative py-16 bg-white overflow-hidden">
    <Helmet>
      <title>Unsubscribe from email communication</title>
      <link rel="canonical" href="https://www.pols.co.uk/unsubscribe"/>
      <meta name="description" content="We will never send you marketing emails of any kind without your express consent. You may opt out here at any time."/>
    </Helmet>

    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="text-lg max-w-prose mx-auto">
        <h1>
          <span className="mt-2 block text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl" data-testid="heading">Unsubscribe</span>
        </h1>
      </div>
      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <p>
          We don't currently collect emails. There is nothing to unsubscribe from.
        </p>
      </div>
    </div>
  </div>
);