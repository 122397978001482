import React from 'react';
import {RichText} from 'prismic-reactjs';
import {DefaultImage} from './defaultImage';
import {BlockQuote} from './blockQuote';
import {EmbeddedVideoTubeVideo} from "./embeddedYouTubeVideo";

export const SliceZone = ({sliceZone}) => (
  sliceZone.map((slice, index) => {
    switch(slice.slice_type) {
      case 'image':
        return <DefaultImage slice={slice} key={`slice-${index}`}/>;
      case 'quote':
        return <BlockQuote slice={slice} key={`slice-${index}`}/>;
      case 'text':
        return <RichText render={slice.primary.text} key={`slice-${index}`}/>
      case 'code':
        return <RichText render={slice.primary.code} key={`slice-${index}`}/>
      case 'youtube':
        return <EmbeddedVideoTubeVideo slice={slice} key={`slice-${index}`}/>
      default:
        return null;
    }
  })
);