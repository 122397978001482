import React from 'react';
import {Link} from "react-router-dom";
import {TwitterIcon} from "./components/icons/TwitterIcon";
import {GitHubIcon} from "./components/icons/GitHubIcon";
import {IconLink} from "./components/IconLink";
import {config} from '../../config';

export const Footer = () => {
  const today = new Date();

  return (
    <footer className="bg-white ">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          <div className="px-5 py-2">
            <Link to='/about-andy-pols/' className="text-base text-gray-500 hover:text-gray-900" data-testid="footer-about-link">
              About
            </Link>
          </div>

          <div className="px-5 py-2">
            <Link to='/archive/' className="text-base text-gray-500 hover:text-gray-900" data-testid="footer-archive-link">
              Archive
            </Link>
          </div>

          <div className="px-5 py-2">
            <a href="/atom.xml" className="text-base text-gray-500 hover:text-gray-900" data-testid="atom-feed-link">
              RSS
            </a>
          </div>
        </nav>

        <div className="mt-2 flex justify-center space-x-6">
          <IconLink url={config.social.twitter} screenReaderLabel="Twitter" icon={<TwitterIcon/>} testid="twitter-footer-link"/>
          <IconLink url={config.gitHub} screenReaderLabel="GitHub" icon={<GitHubIcon/>} testid="github-footer-link"/>
        </div>

        <div className="mt-2 flex justify-center space-x-6 text-base text-gray-400">
          <span className="underline"><Link to='/terms/' data-testid="terms-link">Terms and conditions</Link></span>
          <span className="underline"><Link to='/privacy-policy/' data-testid="privacy-policy-footer-link">Privacy</Link></span>
          <span className="no-underline">Cookie Free Zone</span>
        </div>

        <p className="mt-2 text-center text-base text-gray-400">
          &copy; {today.getFullYear()} {config.name}. All rights reserved.
        </p>
      </div>
    </footer>);
}
