import React from "react";
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {config} from '../../config';
import {MailToLink} from "./components/MailToLink";

export default () => (
  <div className="relative py-16 bg-white overflow-hidden">
    <Helmet>
      <title>Terms & Conditions</title>
      <link rel="canonical" href="https://www.pols.co.uk/terms/"/>
      <meta name="description" content="The legal terms and conditions under which you may use this website."/>
    </Helmet>

    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="text-lg max-w-prose mx-auto">
        <h1>
          <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl" data-testid="heading">Terms and conditions</span>
        </h1>
      </div>
      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>Background: </h2>
        <p>
          These Terms and Conditions, together with any and all other documents referred to herein, set out the terms of use under which you may use this website, {config.website} (“Our Site”).
          Please read these Terms and Conditions carefully and ensure that you understand them. Your agreement to comply with and be bound by these Terms and Conditions is deemed to occur upon your first use of Our
          Site. If you do not agree to comply with and be bound by these Terms and Conditions, you must stop using Our Site immediately.
        </p>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>1. Definitions and Interpretation</h2>
        <p>
          In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings:
        </p>

        <ul>
          <li><span className="font-bold">"Account"</span>: means an account required for a User to access certain areas of Our Site, as detailed in Clause 4;</li>
          <li><span className="font-bold">"Blog"</span>: means a blog hosted on Our Site, created by a User, containing Post(s) submitted by that User;</li>
          <li><span className="font-bold">"Comment"</span>: means a comment on a Blog or Post on Our Site made by a User;</li>
          <li><span className="font-bold">"Content"</span>: means any and all text, images, audio, video, scripts, code, software, databases and any other form of information capable of being stored on a computer that
            appears on, or forms part of, Our Site;
          </li>
          <li><span className="font-bold">"Post"</span>: means a post created by a User in a Blog on Our Site;</li>
          <li><span className="font-bold">"User"</span>: means a user of Our Site; and;</li>
          <li><span className="font-bold">"We/Us/Our"</span>: means {config.company.name}, a company registered in England under {config.company.registrationNumber}, whose registered address is {config.company.registeredAddress}.</li>
        </ul>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>2. Information About Us</h2>
        <ol>
          <li>Our Site, {config.website}, is owned and operated by {config.company.name}, a limited company registered in England under {config.company.registrationNumber}, whose registered address is {config.company.registeredAddress}.
          </li>
        </ol>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>3. Access to Our Site</h2>
        <ol>
          <li>Access to Our Site is free of charge.</li>
          <li>It is your responsibility to make any and all arrangements necessary in order to access Our Site.</li>
          <li>Access to Our Site is provided “as is” and on an “as available” basis. We may alter, suspend or discontinue Our Site (or any part of it) at any time and without notice. We will not be liable to you in any
            way if Our Site (or any part of it) is unavailable at any time and for any period.
          </li>
        </ol>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>4. Accounts</h2>
        <p>
          Our site does not support people creating their own Accounts.
        </p>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>5. Intellectual Property Rights</h2>
        <ol>
          <li>With the exception of the content of Blogs and Posts (see Clause 6), all Content on Our Site and the copyright and other intellectual property rights subsisting in that Content, unless specifically labelled
            otherwise, belongs to or has been licensed by Us. All Content (including the content of Blogs and Posts) is protected by applicable United Kingdom and international intellectual property laws and treaties.
          </li>
          <li>Subject to the licence granted to Us under sub-Clause 6.4, Users retain the ownership of copyright and other intellectual property rights subsisting in the content of their Blogs, Posts and Comments (unless
            any part of that content is owned by a third party who has given their express permission for their material to be used).
          </li>
          <li>For personal use (including research and private study) only, you may:
            <ol>
              <li>Access, view and use Our Site in a web browser (including any web browsing capability built into other types of software or app);</li>
              <li>Download Our Site (or any part of it) for caching;</li>
              <li>Print pages from Our Site;</li>
              <li>Download, copy, clip, print, or otherwise save extracts from pages on Our Site;</li>
              <li>Save pages from Our Site for later and/or offline viewing; and</li>
              <li>View and use other Users’ Blogs, Posts and Comments in accordance with Clause 7.</li>
            </ol>
          </li>
          <li>You may not use any Content (including Blogs, Posts and Comments) downloaded, copied, clipped, printed or otherwise saved from Our Site for commercial purposes without first obtaining a licence to do so
            from Us, our licensors, or from the relevant User, as appropriate. This does not prohibit the normal access, viewing and use of Our Site for general information purposes whether by business users or
            consumers.
          </li>
          <li>You may not systematically copy Content from Our Site with a view to creating or compiling any form of comprehensive collection, compilation, directory, or database unless given Our express permission to do
            so.
          </li>
          <li>Subject to sub-Clauses 5.3 and 5.7 and Clause 7 (governing Blogs, Posts and Comments) you may not otherwise reproduce, copy, distribute, sell, rent, sub-licence, store, or in any other manner re-use
            Content or any other material from Our Site unless given express written permission to do so.
            For further information, please contact Us at <MailToLink url={`mailto:${config.supportEmail}`} label={config.supportEmail}/>.
          </li>
          <li>Our status as the owner and author of the content on Our Site (or that of identified licensors or Users, as appropriate) must always be acknowledged.</li>
          <li>Nothing in these Terms and Conditions limits or excludes the fair dealing provisions of Chapter III of the Copyrights, Designs and Patents Act 1988 ‘Acts Permitted in Relation to Copyright Works’, covering
            in particular the making of temporary copies; research and private study; the making of copies for text and data analysis for non-commercial research; criticism, review, quotation and news reporting;
            caricature, parody or pastiche; and the incidental inclusion of copyright material.
          </li>
        </ol>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>6. Blogs, Posts and Comments</h2>
        <ol>
          <li>An Account is required if you wish to create a Blog, submit Posts, and/or Comment on other Users’ Blogs and Posts. Please refer to Clause 4 for more information.</li>
          <li>You agree that you will be solely responsible for your Blog(s) and Posts and for any Comments you make anywhere on Our Site. Specifically, you agree, represent and warrant that you have the right to use the
            content that you submit and that your Blog(s), Posts, or Comments comply with Our Acceptable Usage Policy, detailed below in Clause 8.
          </li>
          <li>You agree that you will be liable to Us and will, to the fullest extent permissible by law, indemnify Us for any breach of the warranties given by you under sub-Clause 6.2. You will be responsible for any
            loss or damage suffered by Us as a result of such breach.
          </li>
          <li>You (or your licensors, as appropriate) retain ownership of the content of your Blog(s), Posts and Comments, and all intellectual property rights subsisting therein. When you create a Blog, Post, or Comment
            you grant Us an unconditional, non-exclusive, fully transferable, royalty-free, perpetual, [irrevocable,] worldwide licence to use, store, archive, syndicate, publish, transmit, adapt, edit, reproduce,
            distribute, prepare derivative works from, display, perform and sub-licence the content of your Blog, Post or Comment for the purposes of operating or promoting Our Site.
          </li>
          <li>If you wish to remove a Blog or Post, you may do so by contacting Us at <MailToLink url={`mailto:${config.supportEmail}`} label={config.supportEmail}/>. The Blog or Post in question will be deleted from Our
            Site. Removing a Blog or Post also revokes the licence granted to Us to use that Blog or Post under sub-Clause 6.4.
            Please note that caching or references to your Blog or Post may not be made immediately unavailable (or may not be made unavailable at all where they are outside of Our reasonable control).
          </li>
          <li>We may reject, reclassify, or remove any Blogs, Posts or Comments from Our Site where their content, in Our sole opinion, violates Our Acceptable Usage Policy, or if We receive a complaint from a third
            party and determine that the Blog, Post or Comment in question should be removed as a result.
          </li>
        </ol>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>7. Intellectual Property Rights and Use of Other Users’ Blogs, Posts and Comments</h2>
        <ol>
          <li>The content of Blogs, Posts, and Comments on Our Site and the copyright and other intellectual property rights subsisting therein, unless specifically labelled otherwise, belongs to or has been licensed by
            the User identified along with the Blog, Post, or Comment in question. All such content is protected by applicable United Kingdom and international intellectual property laws and treaties.
          </li>
          <li>You may copy and share (reblog) parts of other Users’ Posts and and Comments within Our Site, provided that the original User is credited. If you wish to re-use
            another User’s content in any other way, you must contact the User directly and obtain their express permission by contacting Us at <MailToLink url={`mailto:${config.supportEmail}`} label={config.supportEmail}/>.
          </li>
          <li>Nothing in these Terms and Conditions limits or excludes the fair dealing provisions of Chapter III of the Copyright, Designs and Patents Act 1988 ‘Acts Permitted in Relation to Copyright Works’, covering
            in particular the making of temporary copies; research and private study; the making of copies for text and data analysis for non-commercial research; criticism, review, quotation and news reporting;
            caricature, parody or pastiche; and the incidental inclusion of copyright material.
          </li>
        </ol>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>8. Acceptable Usage Policy</h2>
        <ol>
          <li>
            You may only use Our Site in a manner that is lawful and that complies with the provisions of this Clause 8. Specifically:
            <ol>
              <li>you must ensure that you comply fully with any and all applicable local, national and international laws and/or regulations;</li>
              <li>you must not use Our Site in any way, or for any purpose, that is unlawful or fraudulent;</li>
              <li>you must not use Our Site to knowingly send, upload, or in any other way transmit data that contains any form of virus or other malware, or any other code designed to adversely affect computer hardware,
                software, or data of any kind; and
              </li>
              <li>you must not use Our Site in any way, or for any purpose, that is intended to harm any person or persons in any way.</li>
            </ol>
          </li>
          <li>We hereby exclude any and all liability arising out of any actions (including, but not limited to those set out above) that We may take in response to breaches of these Terms and Conditions.</li>
        </ol>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>9. Links to Our Site</h2>
        <ol>
          <li>
            You may link to Our Site provided that:
            <ol>
              <li>you do so in a fair and legal manner;</li>
              <li>you do not do so in a manner that suggests any form of association, endorsement or approval on Our part where none exists;</li>
              <li>you do not use any logos or trade marks displayed on Our Site without Our express written permission; and</li>
              <li>you do not do so in a way that is calculated to damage Our reputation or to take unfair advantage of it.</li>
            </ol>
          </li>
          <li>You may link to any page of Our Site.</li>
          <li>Framing or embedding of Our Site on other websites is not permitted without Our express written permission. Please contact Us at <MailToLink url={`mailto:${config.supportEmail}`}
                                                                                                                                                           label={config.supportEmail}/> for further information.
          </li>
          <li>We hereby exclude any and all liability arising out of any actions (including, but not limited to those set out above) that We may take in response to breaches of these Terms and Conditions.</li>
          <li>You may not link to Our Site from any other site the content of which contains material that:
            <ol>
              <li>is sexually explicit;</li>
              <li>is obscene, deliberately offensive, hateful, or otherwise inflammatory;</li>
              <li>promotes violence;</li>
              <li>promotes or assists in any form of unlawful activity;</li>
              <li>discriminates against, or is in any way defamatory of, any person, group or class of persons, race, sex, religion, nationality, disability, sexual orientation, or age;</li>
              <li>is intended or is otherwise likely to threaten, harass, annoy, alarm, inconvenience, upset, or embarrass another person;</li>
              <li>is calculated or is otherwise likely to deceive another person;</li>
              <li>is intended or is otherwise likely to infringe (or to threaten to infringe) another person’s privacy;</li>
              <li>misleadingly impersonates any person or otherwise misrepresents the identity or affiliation of a particular person in a way that is calculated to deceive (obvious parodies are not included in this
                definition provided that they do not fall within any of the other provisions of this sub-Clause 9.4);
              </li>
              <li>implies any form of affiliation with Us where none exists;</li>
              <li>infringes, or assists in the infringement of, the intellectual property rights (including, but not limited to, copyright, trade marks and database rights) of any other party; or</li>
              <li>is made in breach of any legal duty owed to a third party including, but not limited to, contractual duties and duties of confidence.</li>
            </ol>
          </li>
        </ol>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>10. Links to Other Sites</h2>
        <p>
          Links to other sites may be included on Our Site. Unless expressly stated, these sites are not under Our control. We neither assume nor accept responsibility or liability for the content of third party sites.
          The inclusion of a link to another site on Our Site is for information only and does not imply any endorsement of the sites themselves or of those in control of them.
        </p>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>11. Advertising</h2>
        <p>
          Our site does not contain any advertising.
        </p>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>12. Disclaimers and Legal Rights</h2>
        <ol>
          <li>Nothing on Our Site constitutes advice on which you should rely. It is provided for general information purposes only.</li>
          <li>Insofar as is permitted by law, We make no representation, warranty, or guarantee that Our Site will meet your requirements, that it will not infringe the rights of third parties, that it will be compatible
            with all software and hardware, or that it will be secure.
          </li>
          <li>If, as a result of Our failure to exercise reasonable care and skill, any digital content from Our Site damages your device or other digital content belonging to you, as a consumer you may be entitled to
            certain legal remedies. For more details concerning your rights and remedies as a consumer, please contact your local Citizens Advice Bureau or Trading Standards Office.
          </li>
          <li>We make reasonable efforts to ensure that Our Content on Our Site is complete, accurate, and up-to-date. We do not, however, make any representations, warranties, or guarantees (whether express or implied)
            that the Content is complete, accurate, or up-to-date.
          </li>
          <li>We are not responsible for the content or accuracy, or for any opinions, views, or values expressed in any Blogs, Posts, or Comments submitted by Users. Any such opinions, views, or values are those of the
            relevant User, and do not reflect Our opinions, views, or values in any way.
          </li>
        </ol>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>13. Our Liability</h2>
        <ol>
          <li>To the fullest extent permissible by law, We accept no liability to any User for any loss or damage, whether foreseeable or otherwise, in contract, tort (including negligence), for breach of statutory duty,
            or otherwise, arising out of or in connection with the use of (or inability to use) Our Site or the use of or reliance upon any Content (whether that Content is provided by Us or contained in Blogs, Posts, or
            Comments created by Users) included on Our Site.
          </li>
          <li>To the fullest extent permissible by law, We exclude all representations, warranties, and guarantees (whether express or implied) that may apply to Our Site or any Content (including Blogs, Posts, or
            Comments created by Users) included on Our Site.
          </li>
          <li>If you are a business user, We accept no liability for loss of profits, sales, business or revenue; loss of business opportunity, goodwill or reputation;
            loss of anticipated savings; business interruption; or for any indirect or consequential loss or damage.
          </li>
          <li>We exercise all reasonable skill and care to ensure that Our Site is free from viruses and other malware, however, subject to sub-Clause 12.3, We accept no liability for any loss or damage resulting from a
            virus or other malware, a distributed denial of service attack, or other harmful material or event that may adversely affect your hardware, software, data or other material that occurs as a result of your use
            of Our Site (including the downloading of any content (including any provided in Blogs, Posts, or Comments) from it) or any other site referred to on Our Site.
          </li>
          <li>We neither assume nor accept responsibility or liability arising out of any disruption or non-availability of Our Site resulting from external causes including, but not limited to, ISP equipment failure,
            host equipment failure, communications network failure, natural events, acts of war, or legal restrictions and censorship.
          </li>
          <li>Nothing in these Terms and Conditions excludes or restricts Our liability for fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of
            liability which cannot be excluded or restricted by law. For full details of consumers’ legal rights, including those relating to digital content, please contact your local Citizens’ Advice Bureau or Trading
            Standards Office.
          </li>
        </ol>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>14. Viruses, Malware and Security</h2>
        <ol>
          <li>We exercise all reasonable skill and care to ensure that Our Site is secure and free from viruses and other malware.</li>
          <li>You are responsible for protecting your hardware, software, data and other material from viruses, malware, and other internet security risks.</li>
          <li>You must not deliberately introduce viruses or other malware, or any other material which is malicious or technologically harmful either to or via Our Site.</li>
          <li>You must not attempt to gain unauthorised access to any part of Our Site, the server on which Our Site is stored, or any other server, computer, or database connected to Our Site.</li>
          <li>You must not attack Our Site by means of a denial of service attack, a distributed denial of service attack, or by any other means.</li>
          <li>
            By breaching the provisions of sub-Clauses 14.3 to 14.5 you may be committing a criminal offence under the Computer Misuse Act 1990. Any and all such breaches will be reported to the relevant law
            enforcement authorities and We will cooperate fully with those authorities by disclosing your identity to them. Your right to use Our Site will cease immediately in the event of such a breach.
          </li>
        </ol>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>15. Privacy and Cookies</h2>
        <p>
          We will only use your personal information as set out in <Link to="/privacy-policy/" data-testid="privacy-policy-link">Our Privacy Policy</Link>.
        </p>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>16. Communications from Us</h2>
        <ol>
          <li>
            We will never send you marketing emails of any kind without your express consent. If you do give such consent, you may opt out at any time. Any and all marketing emails sent by Us include an unsubscribe
            link. Email marketing options can also be changed in <Link to='/unsubscribe/' data-testid="unsubscribe-link">{config.website}/unsubscribe</Link>. for your new preferences to take effect.
          </li>
          <li>For questions or complaints about communications from Us (including, but not limited to marketing emails), please contact Us at <MailToLink url={`mailto:${config.supportEmail}`}
                                                                                                                                                          label={config.supportEmail}/>.
          </li>
        </ol>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>17. Changes to these Terms and Conditions</h2>
        <ol>
          <li>We may alter these Terms and Conditions at any time. Any such changes will become binding on you upon your first use of Our Site after the changes have been implemented. You are therefore advised to check
            this page from time to time.
          </li>
          <li>In the event of any conflict between the current version of these Terms and Conditions and any previous version(s), the provisions current and in effect shall prevail unless it is expressly stated
            otherwise.
          </li>
        </ol>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>18. Contacting Us</h2>
        <p>
          To contact Us, please email Us at <MailToLink url={`mailto:${config.supportEmail}`} label={config.supportEmail}/>.
        </p>
      </div>

      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>19. Law and Jurisdiction</h2>
        <ol>
          <li>These Terms and Conditions, and the relationship between you and Us (whether contractual or otherwise) shall be governed by, and construed in accordance with the law of England & Wales.</li>
          <li>If you are a consumer, you will benefit from any mandatory provisions of the law in your country of residence. Nothing in Sub-Clause 19.1 above takes away or reduces your rights as a consumer to rely on
            those provisions.
          </li>
          <li>If you are a consumer, any dispute, controversy, proceedings or claim between you and Us relating to these Terms and Conditions, or the relationship between you and Us (whether contractual or otherwise)
            shall be subject to the jurisdiction of the courts of England, Wales, Scotland, or Northern Ireland, as determined by your residency.
          </li>
          <li>If you are a business, any disputes concerning these Terms and Conditions, the relationship between you and Us, or any matters arising therefrom or associated therewith (whether contractual or otherwise)
            shall be subject to the exclusive jurisdiction of the courts of England & Wales.
          </li>
        </ol>
      </div>
    </div>
  </div>
);