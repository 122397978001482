export const config = {
  name: 'Andy Pols',
  email: 'andy@pols.co.uk',
  supportEmail: 'support@incrediblesoftware.io',
  website: 'www.pols.co.uk',
  company: {
    name: 'Incredible Software Limited',
    registrationNumber: '11703993',
    registeredAddress: '85 Bartholomew Road, London NW5 2AH'
  },
  prismicApi: 'https://polsblog.prismic.io/api/v2',
  gitHub: 'https://github.com/andypols',
  social: {
    twitter: 'https://twitter.com/andy_pols',
    linkedin: 'https://www.linkedin.com/in/andypols/'
  },
  papers: [{
    title: 'Business Value - Soup To Nuts (Agile 2008)',
    summary: 'These are the slides from the session Chris Matts and I ran at Agile 2008.',
    url: 'https://cdn.pols.co.uk/papers/BusinessValue-SoupToNuts.pdf',
    fileType: 'PDF',
    date: 'August 2008'
  }, {
    title: 'TDD with JMock 2 (SPA2007)',
    summary: 'These are the slides to the workshop Nat Pryce, Romilly Cocking and I ran at SPA about Test Driven Development using JMock 2.0.',
    url: 'https://cdn.pols.co.uk/papers/TDDWithJMock2.pdf',
    fileType: 'PDF',
    date: 'March 2007'
  }, {
    title: 'Getting to Know your Customer (SPA2006)',
    summary: 'These are the slides to workshop Steve Freeman and I ran at SPA.',
    url: 'https://cdn.pols.co.uk/papers/CustomerGamesSpa2006.pdf',
    fileType: 'PDF',
    date: 'March 2006'
  }, {
    title: 'Getting To Know Your Customer (XPDay 2005)',
    summary: 'The slides to the tutorial Steve Freeman and I gave at XPDay5. We talk about different ways to understand your customer.',
    url: 'https://cdn.pols.co.uk/papers/CustomerGamesV2.pdf',
    fileType: 'PDF',
    date: 'December 2005'
  }, {
    title: 'Business Archives Council’s Conference Keynote',
    summary: 'A talk I gave about Agile software and how technology can help Business Archivists',
    url: 'https://cdn.pols.co.uk/papers/BACAnnualConference2004.ppt',
    fileType: 'Power Point',
    date: 'November 2004'
  }, {
    title: 'The Five Business Value Commandments',
    summary: '(Published in Cutter) In this Executive Update, we introduce **our top five guidelines for helping projects deliver business value**. These guidelines allow the project manager to focus on delivering business value when he or she is under pressure to deliver intermediate work products or software. In addition, they show where effort should be expended on a project to derive the most benefit',
    url: 'https://cdn.pols.co.uk/papers/cutterbusinessvaluearticle.pdf',
    fileType: 'PDF',
    date: 'September 2004'
  }, {
    title: 'An Agile Approach To Legacy Systems (XP2004)',
    summary: 'This is an experience report presented at XP2004. It describes how we used Agile Software Development techniques to rewrite a legacy system by focusing on delivering new features!  You can get the [slides here](https://cdn.pols.co.uk/papers/AnAgileApproachToALegacySystem.pdf)',
    url: 'https://cdn.pols.co.uk/papers/agile-approach-to-legacy-systems.pdf',
    fileType: 'PDF',
    date: 'September 2004'
  }, {
    title: 'Business Value Driven Development',
    summary: 'Draft version of paper posted for peer review.  This discusses using business value models to focus the development teams on what is most important for the business.',
    url: 'https://cdn.pols.co.uk/papers/businessvaluedrivendevelopment.pdf',
    fileType: 'PDF',
    date: ''
  }, {
    title: 'The Agile Business Coach',
    summary: 'Draft paper posted for peer review. It’s very much a brain dump!  ',
    url: 'https://cdn.pols.co.uk/papers/businesscoach.pdf',
    fileType: 'PDF',
    date: ''
  }]
}