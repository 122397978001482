import React from "react";
import {Helmet} from 'react-helmet';
import {config} from '../../config';
import {MailToLink} from './components/MailToLink';

export default () => (
  <div className="relative py-16 bg-white overflow-hidden">
    <Helmet>
      <title>About Andy Pols</title>
      <link rel="canonical" href="https://www.pols.co.uk/about-andy-pols"/>
      <meta name="description" content="Andy Pols is an entrepreneurial technologist (He's created several startups and helped numerous companies scale and improve). He's unusual in that he cares about the end user, the customer, the business and not just the technology."/>
    </Helmet>

    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="text-lg max-w-prose mx-auto">
        <h1>
          <span className="mt-2 block text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl" data-testid="heading">About Me</span>
        </h1>
        <p className="mt-8 text-xl text-gray-500 leading-8">I enjoy making stuff people love to use.</p>
      </div>
      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <p>
          Over the years, I’ve written a lot of software, developed products, built teams, got enthusiastic about agile software, became an accidental entrepreneur by selling software I’d created for fun, and helped
          teams improve the way they develop software.
        </p>

        <p>
          I enjoy helping and mentoring startups. Send me an <MailToLink url={`mailto:${config.email}?subject=Pick your brian`} label="email"/> or <a href={config.social.twitter} rel="noreferrer" target="_blank">contact
          me on twitter</a> (DMs open) if you want to pick my brain. Happy to help if I can.
        </p>

        <p>
          You can follow me on Twitter <a href={config.social.twitter} rel="noreferrer" target="_blank">@andy_pols</a> or Linked In <a href={config.social.linkedin} rel="noreferrer" target="_blank">andypols</a>.
        </p>

      </div>
    </div>
  </div>
);